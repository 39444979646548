import { HttpClientModule,HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // Needed for Touch functionality of Material Components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { environment } from '../environments/environment';
import { PendingInterceptorModule } from '../@fury/shared/loading-indicator/pending-interceptor.module';
import { GlobalModule, MY_FORMATS } from './global/global.module';
import { DefaultOAuthInterceptor } from './global/interceptors/default-oauth.interceptor';
import { FurySharedModule } from '../@fury/fury-shared.module';
import { HighlightModule } from '../@fury/shared/highlightjs/highlight.module';
import { FuryCardModule } from '../@fury/shared/card/card.module';
import { BreadcrumbsModule } from '../@fury/shared/breadcrumbs/breadcrumbs.module';
import { PageModule } from '../@fury/shared/page/page.module';
import { ListModule } from '../@fury/shared/list/list.module';
import { QuillModule } from 'ngx-quill';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaFormsModule, RecaptchaModule, RecaptchaV3Module} from "ng-recaptcha";
import { GoogleMapsModule }  from  '@angular/google-maps' ;
import { MaterialModule } from '../@fury/shared/material-components.module';

@NgModule({
  imports: [
    // Angular Core Module // Don't remove!
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    GoogleMapsModule,

    // Fury Core Modules
    AppRoutingModule,

    // Layout Module (Sidenav, Toolbar, Quickpanel, Content)
    LayoutModule,

    // Google Maps Module
   /* AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey,
      libraries: ['places']
    }),*/

    // Displays Loading Bar when a Route Request or HTTP Request is pending
    PendingInterceptorModule,

    // Register a Service Worker (optional)
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })

    GlobalModule,
    FurySharedModule,
    // Core
    HighlightModule,
    FuryCardModule,
    BreadcrumbsModule,
    MaterialModule,
    PageModule,
    ListModule,
    QuillModule.forRoot(),
    RecaptchaV3Module,
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
   
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultOAuthInterceptor,
      multi: true
    },
    {
       provide: RECAPTCHA_V3_SITE_KEY, 
       useValue: environment.recapchaV3ApiKey
    }
  
  ]
})
export class AppModule {
}


