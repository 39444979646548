<div class="col-sm-12 row" >
  
    <div  class="col-sm-5">
        <div *ngIf="showTitles" class="h3 secondary-text" style="font-size: 18px;">{{titleAviable}}</div>
        <qx-select-list #select2></qx-select-list>
    </div>
    <div class="col-sm-2 row" style="justify-content: center; text-align: center;padding-top: 100px;">
        <button  mat-icon-button class=" col-12  icon-btn" (click)="addAllItem()">
            <mat-icon matTooltip="Seleccionar Todos">last_page </mat-icon>
        </button>
        <button mat-icon-button class=" col-12  icon-btn" (click)="addItem()">
            <mat-icon matTooltip="Seleccionar">navigate_next</mat-icon>
        </button>
        <button mat-icon-button class=" col-12  icon-btn" (click)="removeItem()">
            <mat-icon matTooltip="Deshacer">navigate_before</mat-icon>
        </button>
        <button mat-icon-button class=" col-12  icon-btn" (click)="removeAllItem()">
            <mat-icon matTooltip="Deshacer Todo">first_page</mat-icon>
        </button>
    </div>
    <div  class="col-sm-5">
        <div *ngIf="showTitles" class="h3 secondary-text" style="font-size: 18px;">{{titleSelected}}</div>
        <qx-select-list #select1></qx-select-list>
    </div>

</div>