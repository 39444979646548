import {Injectable} from '@angular/core';
import {AppConfig} from '../models/app-config';
import { AppUtils } from '../helpers/app.utils';

@Injectable({
    providedIn: 'root',
})
export class AppConfigService {
    private appConfig: AppConfig;

    constructor() {
        this.appConfig = {
            apiUrl: ''
        };
    }

    public getAppConfig(): AppConfig {
        return this.appConfig;
    }

    public setAppConfig(appConfig: AppConfig): void {       
        this.appConfig = appConfig;
    }

    
}
