import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { NgZone, Injectable } from "@angular/core";
import { AppUtils } from "./app.utils";
import { InfoComponent } from './custom-snack-bar/info.component';
import { Spin } from './spin';

@Injectable({
    providedIn: 'root'
})
export class SnackBarService {
    spin: Spin;
    constructor(public snackBar: MatSnackBar, private zone: NgZone) {
        this.spin = new Spin();
    }

    open(message, action = 'CERRAR', duration = 8000) {
        this.zone.run(() => {
            this.snackBar.open(message, action, { duration });
        })
    }

    error(message){
        this.zone.run(() => {
            this.snackBar.open(message, 'CERRAR', {
                 duration : 8000,
                 panelClass:['snack-bar-error'],
                 verticalPosition:'top',
                // horizontalPosition: "center"
                });
        }) 
    }

    success(message){
        this.zone.run(() => {
            this.snackBar.open(message, 'CERRAR', {
                 duration : 8000,
                 panelClass:['snack-bar-success'],
                 verticalPosition:'top',
                 // horizontalPosition: "center"
                });
        }) 
    }

    warning(message){
        this.zone.run(() => {
            this.snackBar.open(message, 'CERRAR', {
                 duration : 8000,
                 panelClass:['snack-bar-warning'],
                 verticalPosition:'top',
                 // horizontalPosition: "center"
                });
        }) 
    }

    info(message){
        this.zone.run(() => {
            this.snackBar.open(message, 'CERRAR', {
                 duration : 300000,
                 panelClass:['snack-bar-info'],
                 verticalPosition:'top',
                 // horizontalPosition: "center"
                });
        }) 
    }

    infoCustom(message){
   var configSucess:MatSnackBarConfig<any>={
          duration : 7200000,
          panelClass:['snack-bar-info'],
          verticalPosition:'top',
          // horizontalPosition: "center"
       };

      let snackBarRef=  this.snackBar.openFromComponent(InfoComponent,{
            data:message,
            ...configSucess
        })
    }

    verification(res) {     
        this.spin.spinStop();
        switch (res.status) {
            case 201:
                this.success("Datos guardados correctamente.");               
                break;
            case 204:
                this.success("Eliminado correctamente.");  
                break;
            case 200:
                //this.success("Sus datos han sido guardados satisfactoriamente.");  
                break;
            case 422:
                this.error( res.json().errors[0].detail + '.');                
                break;
            case 401:
              //  let msj = res.error.error_description;
                this.error("Su sesión a expirado. Por favor ingrese nuevamente");  
               AppUtils.logout();
                break;
            case 0:
            case 504:
                this.error("No existe conexión con el servidor.");               
                break;
            case 500:
                this.error("Error en el servidor.");
                break;
            case 400:
                this.error("Error en el envio de datos.");               
                break;
            case 404:
                this.error("Dirección no encontrada..");  
                break;
        }
    }

    showMessage(type = 'success', mess) {
        switch (type) {
            case 'success':
              this.success(mess);  
                break;
            case 'error':
               this.error(mess);  
                break;
        }
    }

}