import {
    Component, OnInit
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { DialogPerfilService } from './dialog-perfil.service';
import { AppUtils } from '../../helpers/app.utils';
import { Notifications } from '../../helpers/notifications';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { SnackBarService } from '../../helpers/snack-bar.service';

@Component({
    selector: 'app-dialog-perfil',
    templateUrl: './dialog-perfil.component.html',
    styleUrls: ['./dialog-perfil.component.scss']
})
export class DialogPerfil implements OnInit {
    saved = false;
    form: FormGroup;
    perfils = [];
    selected = null;
    currentUser: any;   
    notification: Notifications;

    constructor(public dialogRef: MatDialogRef<any>,
                public dialog: MatDialog,
                public perfilService: DialogPerfilService,
                public fb: FormBuilder,private snackBarService:SnackBarService) {
                 this.notification = new Notifications();

    }

    ngOnInit() {
        this.currentUser = AppUtils.currentUser();
        this.form = this.fb.group({
            actualClave: [null, Validators.required],
            nuevaClave: [null, Validators.required],
            nuevaClave2: [null, Validators.required],
        });
    }

    salir(){
        this.dialogRef.close()
    }

    save(values): void {
        this.perfilService.update(values).subscribe(res => {
            if (res.success) {
                 this.snackBarService.showMessage('success', (res.message || 'Contraseña actualizada.'));
                this.dialogRef.close();
            } else {
                 this.snackBarService.showMessage('error', res.message);
            }
        }, error1 => {
             this.snackBarService.showMessage('error', error1.message);
        });

       
      
    }    
}
