<div class="ng-pagination" style="padding-top: 20px;">
    <!--<pagination-controls (pageChange)="pageChanged($event)"-->
    <!--[previousLabel]="'Anterior'"-->
    <!--class="text-center"-->
    <!--[nextLabel]="'Siguiente'"-->
    <!--(click)="onSearch($event)"-->
    <!--&gt;</pagination-controls>-->
    <form [formGroup]="form">
        <mat-form-field style="width: 65px;" class="input-container input-inline">
            <mat-select formControlName="itemperpage">
                <mat-option [value]="10">10</mat-option>
                <mat-option [value]="20">20</mat-option>
                <mat-option [value]="30">30</mat-option>
                <mat-option [value]="40">40</mat-option>
                <mat-option [value]="50">50</mat-option>
                <mat-option [value]="100">100</mat-option>
            </mat-select>
        </mat-form-field>
    </form>
    <a style="padding-right:10px;padding-top: 17px; " class="page-item" [ngClass]="{'disabled':!currentpage}" (click)="firstPage()">
        <span class="fa fa-chevron-left"></span>
        <span class="fa fa-chevron-left"></span>
    </a>
    <a style="padding-right:10px;padding-top: 17px; " class="page-item" [ngClass]="{'disabled':!currentpage}" (click)="prevPage()"><span
            class="fa fa-chevron-left"></span></a>
    <div class="ng-pagination-data" style="padding-top: 17px;">
        <div *ngIf="currentpage == 0 && totalitems > 0">
            Resultado <strong>1</strong> - <strong>{{currentitems}}</strong> de <strong>{{totalitems}}</strong>
        </div>
        <div *ngIf="currentpage != 0 && totalitems > 0">
            Resultado <strong>{{(currentpage) * itemperpage + 1}}</strong> - <strong>{{currentitems + (currentpage) *
            itemperpage }}</strong> de <strong>{{totalitems}}</strong>
        </div>
    </div>
    <a style="padding-left:10px;padding-top: 17px; " class=" page-item" [ngClass]="{'disabled':currentpage + 1 >= totalpage}"
       (click)="nextPage()"><span
            class="fa fa-chevron-right"></span></a>
    <a style="padding-left:10px;padding-top: 17px; " class="page-item" [ngClass]="{'disabled':currentpage + 1 >= totalpage}" (click)="lastPage()">
        <span class="fa fa-chevron-right"></span>
        <span class="fa fa-chevron-right"></span>
    </a>
</div>
