<div class="sidenav-area">      
    <header>
        <app-header></app-header>
    </header>
    <mat-sidenav-container>
        <mat-sidenav #start [mode]="mode">
            <app-menu></app-menu>
        </mat-sidenav>
        
        <main>
            <div class="container-fluid padding-bottom-20 mat-body">
                <router-outlet></router-outlet>
            </div>
        </main>
    </mat-sidenav-container>
</div>




