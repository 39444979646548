import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/global/models/user';
import { AppUtils } from 'src/app/global/helpers/app.utils';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogPerfil } from 'src/app/global/pages/dialog-perfil/dialog-perfil.component';
import { AuthenticationService } from '@quiox/global/services/authentication.service';
import { SnackBarService } from '@quiox/global/helpers/snack-bar.service';
import { Spin } from '@quiox/global/helpers/spin';

@Component({
  selector: 'fury-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  styleUrls: ['./toolbar-user.component.scss']
})
export class ToolbarUserComponent implements OnInit {

  isOpen: boolean;
  currentUser: User;
  verAyuda: boolean = false;
  dialogPerfil: MatDialogRef<DialogPerfil>;
  spin: Spin

  constructor(public router: Router, public dialog: MatDialog,public auth: AuthenticationService, public snackBarService: SnackBarService) { 
    this.spin = new Spin();
  }

  ngOnInit() {
    this.currentUser = AppUtils.currentUser();
    this.verAyuda = AppUtils.verifyPerm("ROLE_VER_AYUDA") || AppUtils.verifyPerm("ROLE_MASTER")
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }

  logout() {
   /* this.spin.spinLoading();
    this.auth.logoutOauth().subscribe(res => {
      if (res.success) {
        this.spin.spinStop();
        AppUtils.logout();    
        this.snackBarService.success("Sesión finalizada correctamente");     
      }
      else {
        this.spin.spinStop();
        this.snackBarService.error(res.message);        
      }
    });*/
    
    //alert("cerrando seccion");
    AppUtils.logout();
  
  }

  ayuda() {
    const url: string = '/webapp/ayuda';
    this.router.navigateByUrl(url);
  }

  cambiarContrasena() {
    this.dialogPerfil = this.dialog.open(DialogPerfil, {
      disableClose: true
    });
  }
 

}
