import {Routes, RouterModule} from '@angular/router';
import {AuthComponent} from './auth.component';
import {LoginComponent} from './login/login.component';

const routes: Routes = [
    {
        path: 'webapp',
        component: AuthComponent,
        children: [
            {path: '',redirectTo: 'login',pathMatch: 'full' },
            {path: 'login', component: LoginComponent }
        ]
    }
];

export const routingAuth = RouterModule.forChild(routes);
