import {Injectable, EventEmitter} from '@angular/core';
import {HttpClient, HttpBackend} from '@angular/common/http';
import {AppConfig} from '../models/app-config';
import { AppUtils } from '../helpers/app.utils';
import { AppConfigService } from './app-config.service';

@Injectable({
    providedIn: 'root'
  })
export class AppConfigLoaderService {
    constructor(private httpClient :  HttpClient,private appConfigService : AppConfigService) {     
    }

    public loadConfiguration() {      
    return new Promise<boolean>((resolve) => {
            this.httpClient.get<AppConfig>('./assets/config/app-config.json')
                .subscribe((appConfig) => {                                  
                 //this.appConfigService.setAppConfig(appConfig);                               
                   AppUtils.save('apiUrl',appConfig.apiUrl);          
                    resolve(true);
                });
        });
        
    }

   
}
