import { SidenavItem } from 'src/app/layout/sidenav/sidenav-item/sidenav-item.interface';

export const config = {
    modules: []
};

export interface ModuleConfig {
    id: string;
    name: string;
    title?: string;
    menu: MenuItem[];
}

export interface ModuleFuryConfig {
    name: string;
    menu: SidenavItem[];
}

export interface MenuItem {
    name: string;
    url?: string;
    roles?: string[];
    childrens?: MenuItem[];
}

export const DATE_FORMAT_API = 'YYYY-MM-DD';

export const DATETIME_FORMAT_API = 'YYYY-MM-DDTHH:mm';

export const DATE_FORMAT_VIEW = 'DD/MM/YYYY';

export const DATETIME_FORMAT_VIEW = 'DD/MM/YYYY HH;mm a';