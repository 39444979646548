import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';


@Injectable({
    providedIn: 'root'
  })
export class QxFilterListService {
    constructor(protected httpClient: HttpClient) {
    }


    get(url, texto = '', allParams) {
        return new Promise<any>((resolve, reject) => {
            let params = new HttpParams();
            params = params.set('q', texto);
            for (var key in allParams) {
                params = params.set(key, allParams[key]);
            }
            this.httpClient.get(url, {params}).subscribe(res => {
                resolve(res);
            }, error => {
                reject(error);
            });
        });
    }
}
