import {User} from '../models/user';
import {BehaviorSubject, Observable} from 'rxjs';
import * as moment from "moment";
import { EventEmitter } from '@angular/core';
import { APP_CONFIG } from 'src/app/app.config';

declare const alertify: any;

export class AppUtils {
   
    public static sessionStatusSubject: BehaviorSubject<string> = new BehaviorSubject('void');
    public static sessionTime: number = 30 * 60 * 1000; // 30 minutos
    public static intervalExp: number = 30;
    public static filtersCache: any = {};
    public static tag: any;
    
    public static logout() {    
       // this.spin.spinLoading(); 
        AppUtils.del('currentUser');    
        AppUtils.del('access_token');   
        const config = APP_CONFIG[APP_CONFIG.active];
        const url_logout_hosted_ui =config.url_logout_hosted_ui;
        window.location.href=url_logout_hosted_ui;      
     //   AppUtils.sessionStatusSubject.next('logout');
    }

    public static clearLocalStorage() {
        localStorage.clear();

        /*const appKeys: Array<any> = AppUtils.load('appKeys') || [];
        if (appKeys.length) {
            appKeys.map(key => {
                AppUtils.del(key);
            });
        }
        AppUtils.del('appKeys');*/
    }

    public static save(key, val) {
        localStorage.setItem(btoa(key), btoa(JSON.stringify(val)));
        let appKeys: Array<any> = AppUtils.load('appKeys') || [];
        if (AppUtils.isNullOrUndefined(appKeys)) {
            appKeys = [];
        }
        appKeys.push(key);
        localStorage.setItem(btoa('appKeys'), btoa(JSON.stringify(appKeys)));

    }

    public static load(key) {
        try {
            return JSON.parse(atob(localStorage.getItem(btoa(key))));
        } catch (e) {
            return null;
        }
    }

    public static del(key) {
        localStorage.removeItem(btoa(key));
    }

    public static currentUser(): User {
        return AppUtils.load('currentUser');
    }

    public static getRecapchaV3Token(){
        return AppUtils.load('Recaptchav3-Token');
    }

    public static getActionName() {
        return AppUtils.load('Action-Name');
    }

    public static delActionName() {
        this.del('Action-Name');
    }

    public static delRecapchaV3Token() {
        this.del('Recaptchav3-Token');
    }

    public static saveRecapchaV3Token(token) {
      this.save('Recaptchav3-Token',token);
    }

    public static saveActionName(action){
        this.save('Action-Name',action);
    }

    public static urlApi(): string {
        return AppUtils.load('apiUrl');
    }

    public static updateLastRequest() {
        const actualTime = moment().unix();
        this.save('last-request', actualTime)
    };

    public static initTimeTokend(exp) {
        const actualTime = moment().unix();
        this.save('exp-time', actualTime + exp)
    };

    public static setUserTokend(data) {
        let user = AppUtils.currentUser();        
      //  user.refreshToken = data.refreshToken;
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        AppUtils.save('currentUser', user);
       // AppUtils.initTimeTokend(data.exp);
        AppUtils.sessionStatusSubject.next('live');
    }

    public static verifyAnyPerm(roles: []) {
        let verify = false;
        roles.forEach(rol => {
            if (AppUtils.verifyPerm(rol)) {
                verify = true;
            }
        });
        return verify;
    }

    public static verifyPerm(rol) {
        const user = AppUtils.currentUser(); 
       // console.log("curennnnnuser",user)
        if(!AppUtils.isNullOrUndefined(user)){
        return user.authorities.filter(a => a.authority == rol).length > 0;
       }
       return false;
    }

    public static clone(data: any): any {
        const str = JSON.stringify(data);
        return JSON.parse(str);
    }

    public static back() {
        window.history.back();
    }

    public static success(message: string) {
        alertify.success('<i class="fa fa-check-circle"></i> ' + message);                
    }

    public static error(message: string) {
        alertify.error('<i class="fa fa-warning"></i> ' + message);
    }

    public static confirm(message: string, callback: CallbackFn) {
        alertify.defaults.theme.ok = "btn btn-primary"
        alertify.defaults.theme.cancel = "btn"
        alertify.dialog('confirm')
            .setting({
                'title': '<strong>ATENCIÓN</strong>',
                'labels': { ok: 'ACEPTAR', cancel: 'CANCELAR' },
                'message': message,
                'onok': callback,
                'closableByDimmer': false,
                'reverseButtons': true,
            }).show();
    }

   /*  alertify.defaults.theme.ok = "btn btn-primary"
    alertify.defaults.theme.cancel = "btn"
    alertify.defaults.theme.input = "form-control"
    alertify.prompt('confirm')
      .setting({
        'title': '<strong>ATENCIÓN</strong>',
        'labels': { ok: 'ACEPTAR', cancel: 'CANCELAR' },
        'message': 'Introduzca el nombre del documento',
        'type': 'text',
        'closableByDimmer': false,
        'reverseButtons': true,
        'value': null,
        'onok': (evt, value) => {
          if (!AppUtils.isNullOrUndefined(value) && value != "")
            this.guardarDocumentosOtro(file, value);
        },
      }).show();
 */

public static selectFirstMenuShow(menus) {  
    var primermenu = null;
    for (let index = 0; index < menus.length; index++) {      
        const element = menus[index];
        if(element.routeOrFunction){     
            primermenu = element;
            return primermenu;
        }
        if(element.subItems){
            for (let index = 0; index < element.subItems.length; index++) {  
                const subElement = element.subItems[index];
                if(subElement.routeOrFunction){     
                    primermenu = subElement;
                    return primermenu;
                }                  
            }
         
        }
    }

}


    public static prompt(message: string, type: string = 'text'): Observable<any> {
        const event = new EventEmitter();

        alertify.prompt('confirm')
            .setting({
                'title': 'Atención',
                'labels': {ok: 'Si', cancel: 'No'},
                'message': message,                
                'type': type,
                'onok': (evt, value) => event.emit(value),
            }).show();
        
        return event;
    }

    public static getOauthCredential():any{
        return  {           
            key: 'ayto',
            secret: 'ayto-api'
          }
    }

   

     //////////////////////////////////////////////////////

     public static isArray(object: any): boolean{
        return Array.isArray(object);
      }
    
      public static isBoolean(object: any): boolean{
       return typeof object === 'boolean';
      }
    
      public static isFunction(object: any): boolean{
       return typeof object === 'function'
      }
    
      public static isNull(object: any): boolean{
       return object === null;
      }
    
      public static isNullOrUndefined(object: any): boolean{
          return object === null || object === undefined;
      }
    
      public static isUndefined(object: any): boolean{
           return object === undefined
       }
    
       public static isNumber(object: any): boolean{
        return typeof object === 'number';
      }
     
      public static isObject(object: any): boolean{
          return object !== null && typeof object === 'object'
      }
     
      public static isString(object: any): object is string{
          return typeof object === 'string';
      }


      public static initHostedUi(){
        const config = APP_CONFIG[APP_CONFIG.active];
        const url_hosted_ui =config.url_hosted_ui;
        window.location.href=url_hosted_ui;     
    }
     
      public static urlGateway() {
        const config = APP_CONFIG[APP_CONFIG.active];
        const url =config.url_gateway;
         return url;
      }
      

      public static releaseWeb() {
        return "26.12.2023.1";
      }
  

    
}


export interface CallbackFn {
    (input): void;
}
