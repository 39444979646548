<div mat-dialog-title class="col-sm-12">
        {{title}}
      <mat-icon  style="cursor:pointer; float: right;padding-left: 223px;" (click)="dialogRef.close()">close</mat-icon> 
</div>

<mat-dialog-content >
        <form [formGroup]="form" class="padding-top-10">
            <mat-form-field class="col-sm-6" style="padding-right: 15px;">
                <mat-label>Fecha de inicio</mat-label>
                <input matInput [matDatepicker]="minDatePicker" formControlName="minDate" (keypress)="keyPress($event)">
                <mat-datepicker-toggle matSuffix [for]="minDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #minDatePicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="col-sm-6" style="padding-right: 15px;">
                <mat-label>Fecha de fin</mat-label>
                <input matInput [matDatepicker]="maxDatePicker" formControlName="maxDate" (keypress)="keyPress($event)">
                <mat-datepicker-toggle matSuffix [for]="maxDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #maxDatePicker></mat-datepicker>
            </mat-form-field>
        </form>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" style="padding-bottom: 20px;">
            <button type="button" mat-button (click)="dialogRef.close()">
                CANCELAR
            </button>
            <button [disabled]="!form.valid" type="button" mat-button color="primary" style="
                        color: #ffff;cursor: pointer; background-color: #388e3c;"
                (click)="acepted()">
                ACEPTAR
            </button>
        </mat-dialog-actions>
