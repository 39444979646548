    import {
    Component, ViewChild
} from '@angular/core';
import {AdDirective} from './directives/ad.directive';
import { NomencladorFormComponent } from '../nomenclador-form.component';
import $ from 'jquery';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AppUtils } from '@quiox/global/helpers/app.utils';

@Component({
    selector: 'app-dialog-nomenclador',
    templateUrl: './dialog-nomenclador.component.html',
    styleUrls: ['./dialog-nomenclador.component.scss']
})
export class DialogNomencladorComponenet {
    title: string = '';
    validForm = false;
    ver=true;
    //saved = false;
    id = 0;
    componentInstance: any;
    @ViewChild(AdDirective, {static: false}) adHost: AdDirective;

    constructor(public dialogRef: MatDialogRef<any>, public dialog: MatDialog) {

    }

    componentLoader(factory, object = null, edit = false,ver) {       
        this.ver=ver;
        
        if (!AppUtils.isNullOrUndefined(factory)) {
            let viewContainerRef = this.adHost.viewContainerRef;
            viewContainerRef.clear();
            const containerRef = viewContainerRef.createComponent(factory);
            this.componentInstance = <NomencladorFormComponent>containerRef.instance;
            if (!ver){
                this.title = "Detalles";
            }
            else{
                this.title = this.componentInstance['title'];
            } 
            
            setTimeout(() => {
                this.componentInstance.form.valueChanges.subscribe(res => {
                    this.validForm = this.componentInstance.form.valid;
                });

                if (!AppUtils.isNullOrUndefined(object)) {
                    this.componentInstance.form.patchValue(object);
                    this.componentInstance.onModelLoaded(object);
                    this.id = object.id;

                    if (!ver) {
                        this.componentInstance.form.disable();
                    }
                }         
            });           

            if(!AppUtils.isNullOrUndefined(this.componentInstance.closeBehavior)){
                this.componentInstance.closeBehavior.subscribe(res=>{
                    if(res){
                        //this.saved = true;
                        this.dialogRef.close();
                    }
                })
            }
        }
    }

    async save() {
        const data = await this.componentInstance.save();        
        if (data.success) {
            //this.saved = true;
            if (!this.componentInstance.keepOpen)
                this.dialogRef.close();
        }
    }

    delete() {
        this.componentInstance.removeConfirm();
        // this.saved = true;
        // this.dialogRef.close();
    }

}
