import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { map } from 'rxjs/operators';
import { ThemeService } from '../../../@fury/services/theme.service';
import { NavigationService } from '@quiox/global/services/navigation.service';
import { User } from '@quiox/global/models/user';
import { configCustom } from 'src/app/src/config/config';
import { AppUtils } from '@quiox/global/helpers/app.utils';
import { Router } from '@angular/router';
import {MatTabChangeEvent, MatTabGroup} from '@angular/material/tabs';

@Component({
  selector: 'fury-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  @Input()
  @HostBinding('class.no-box-shadow')
  hasNavigation: boolean;

  @Output() openSidenav = new EventEmitter();
  @Output() openQuickPanel = new EventEmitter();

  @ViewChild("#tabgroup",{ static : false}) tabgroup : MatTabGroup

  topNavigation$ = this.themeService.config$.pipe(map(config => config.navigation === 'top'));

  parentTabSelected = false;
  showMenu: boolean = true;
  modules = [];
  modulesMenuHorizontal = [];
  modulesMenuByPoint = [];
  currentUser: User;
  indexTab = 0;
  proyecName= configCustom.projectName;
  appInfo: any = {};

  constructor(private themeService: ThemeService,public navigation: NavigationService, public router: Router) {
  }

  ngOnInit() {
    this.currentUser = AppUtils.currentUser();        
    this.modules = this.navigation.allModules;
    console.log('Modules :>> ', this.modules);
   }

   selectModule(external = false) {
    const module = this.modules[this.navigation.activeTab];
    if (!AppUtils.isNullOrUndefined(module)) {
        AppUtils.save('activeTab', this.navigation.activeTab); 
        this.navigation.cleanMenuItem();           
        this.navigation.setMenuItemsFury(module.menu); 
        const firstMenu = AppUtils.selectFirstMenuShow(module.menu);          
        if (!AppUtils.isNullOrUndefined(firstMenu)) {
            if (external)
                this.router.navigateByUrl(firstMenu.routeOrFunction);
            else {
                let index = -1;
                for (let i in this.modules) {
                  //  const urlOk = this.urlOk(this.modules[i].menu);
                  //  if (urlOk) {
                        index = parseInt(i);
                  //  }
                }
                
                if (index >= 0)
                    this.navigation.activeTab = index;
            }
        }
    }
     
}

    selectModuleInMenu(external2 = false, fIndex) {
        if (fIndex >= 4) {
            let element = this.modules[fIndex];
            this.modules.splice(fIndex, 1);
            this.modules.unshift(element);
            this.navigation.activeTab = 0;
            this.navigation.modules=this.modules;
        }
       this.selectModule(external2);
       this.tabgroup.selectedIndex = 0;


    }




urlOk(menus) {
    let urlOk = menus.filter(m => this.router.url.indexOf(m.url) > -1 && m['show']);
    if (!urlOk.length) {
        menus.forEach(m => {
            if (m.childrens) {
                urlOk = this.urlOk(m.childrens);
                if (urlOk) {
                    return urlOk;
                }
            }
        });
    }
    return urlOk.length;
}

findAuthorityMenu(authority, menu) {
    let access = false;
    menu.forEach(item => {
        if (item.roles && item.roles.indexOf(authority) >= 0) {
            access = true;
            item['show'] = true;
        } else {
            item['show'] = false;
        }
        if (item.childrens) {
            access = this.findAuthorityMenu(authority, item.childrens);
            item['show'] = access;
        }
    });
    return access;
}


}
