import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
  })
export class DialogPerfilService {
    constructor(protected httpClient: HttpClient) {
    }

    update(object: any): Observable<any> {
        return this.httpClient.put('api/user/me/password', object);
    }
}
