<div mat-dialog-title>
    Atención
    <mat-icon style="cursor:pointer !important; float: right !important;padding-left: 282px;
    padding-right: 24px; " (click)="dialogRef.close()">close</mat-icon>
</div>

<mat-dialog-content>
    <form [formGroup]="form">

        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="24px">
         <!--    <mat-label fxFlex *ngIf="!estado">Se detectaron los siguientes errores: </mat-label> -->
        </div>

        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="24px">
            <div *ngIf="!estado" class="padding-top-10">
                <textarea fxFlex readonly dropzone="block"
                    style="margin-top: 0px; margin-bottom: 0px; height: 195px; width: 605.992px; margin-left: 18px;"
                    matInput formControlName="message"></textarea>
            </div>
            <div fxFlex *ngIf="estado" style="padding-top: 25px; text-align: center">
                <h2>{{message}}</h2>
            </div>
        </div>

    </form>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" style="padding-bottom: 20px;">
    <button type="button" mat-button color="primary" style="
                    color: #ffff;cursor: pointer; background-color: #388e3c;" (click)="salir()">
        ACEPTAR
    </button>
</mat-dialog-actions>