import {Injectable} from '@angular/core';
import {AppUtils} from '../helpers/app.utils';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {NavigationService} from './navigation.service';


@Injectable()
export class AuthenticationService {
    public synced: boolean;

    constructor(private http: HttpClient, private navigation: NavigationService) {
    }


    login(username: string, password: string) {

        this.synced = false;
        const oauthCredentials: any = AppUtils.getOauthCredential();

        let headers = new HttpHeaders();
        headers = headers.set('Authorization', 'Basic ' + btoa(oauthCredentials.key + ':' + oauthCredentials.secret));
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
        const body = new HttpParams()
            .set('username', username)
            .set('password', password)
            .set('grant_type', 'password');
        // const body = `grant_type=password&username=${username}&password=${password}`;            ''
        return this.http.post('/oauth/token', body, {headers: headers});
        // return this.http.get(config.urls.base_auth_url, {headers: headers});
    }

    loadSession() {
        AppUtils.sessionStatusSubject.next('live');
        return this.http.get('api/user/me');
    }

    sync(data: any = {}) {
        this.synced = true;
        const user = AppUtils.load('currentUser');
    }

    logout() {
        this.navigation.cleanMenuItem();
        AppUtils.logout();
        this.synced = false;
    }

    public appInfo(): Observable<any> {
        return this.http.get("api/pago-predial/app-info");
    }

    public logoutOauth(): Observable<any> {
        return this.http.post("api/logout", null);
    }

}
