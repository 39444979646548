import {
    Component, Input, AfterViewInit, Output, EventEmitter, ViewChild, forwardRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { merge } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'qx-time',
    templateUrl: './qx-time.component.html',
    styleUrls: ['./qx-time.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => QxTimeComponent),
        multi: true
    }
    ]
})
export class QxTimeComponent implements ControlValueAccessor {
    _value: string;
    hoursControl: FormControl = new FormControl();
    minutesControl: FormControl = new FormControl();
    ampmControl: FormControl = new FormControl('AM');

    @Input() placeholder;
    @Input() required;

    hList: number[] = [];
    mList: number[] = [];

    constructor() {
        this.mList = [0];
        for (var i = 1; i <= 59; ++i) {
            if (i <= 12) {
                this.hList.push(i);
            }
            this.mList.push(i);
        }
    }

    ngOnInit() {
        merge(this.hoursControl.valueChanges, this.minutesControl.valueChanges, this.ampmControl.valueChanges).pipe(
            map(_ => {
                let hours = this.hoursControl.value;
                let minutes = this.minutesControl.value;
                let ampm = this.ampmControl.value;                
                if (hours && minutes >= 0) {

                    if (ampm == 'PM' && hours != 12) {
                        hours = hours + 12;
                    }

                    if (ampm == 'AM' && hours == 12) {
                        hours = 0;
                    }

                    var m = moment();
                    m = m.hour(hours);
                    m = m.minute(minutes);
                    return m.format('HH:mm');
                } else {
                    return null
                }

            }
            )).subscribe(res => {
                this.value = res as string;
                this.propagateChange(this.value);
            });

        this.hoursControl.valueChanges.subscribe(h => {
            if (!this.minutesControl.value) {
                this.minutesControl.setValue(0);
            }
        });
    }

    procesar(t: number) {
        if (t < 10) {
            return "0" + t;
        }
        return t;
    }

    propagateChange = (_: any) => { };

    get value() {
        const time = this._value;
        return time;
    }

    set value(val) {
        if (val) {
            this._value = val;
            this.propagateChange(this._value);
        }
    }

    writeValue(value: any): void {
        if (value !== undefined) {
            var m = moment(value, 'HH:mm');
            let ampm = 'AM'
            let hora = m.hour();
            if (hora > 12) {
                hora = hora - 12;
                ampm = 'PM'
            }
            this.hoursControl.setValue(hora);
            this.minutesControl.setValue(m.minute());
            this.ampmControl.setValue(ampm);

            this.value = value;
            this.propagateChange(this.value);
        }
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState?(isDisabled: boolean): void {
        //throw new Error("Method not implemented.");
    }
}
