import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';
import { AppUtils } from '../../../helpers/app.utils';
import { NavigationService } from '../../../services/navigation.service';
import { SnackBarService } from '@quiox/global/helpers/snack-bar.service';

declare const $;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],

})
export class LoginComponent implements OnInit {
    form: FormGroup;
    url: string;
    modoPrueba: boolean = false;
    modo :  boolean = true;

    constructor(public fb: FormBuilder,
        public auth: AuthenticationService,
        public router: Router,
        private navigation: NavigationService,
        public route: ActivatedRoute,private snackBarService:SnackBarService) {
    }

    ngOnInit() {
        this.form = this.fb.group({
            user: [null, [Validators.required]],
            pass: [null, [Validators.required]],
            modo: [null],
        });

        this.form.get("modo").valueChanges.subscribe((modo :  String) =>{
             if(modo =="prueba"){
                 this.modo=false;
             }else{
                this.modo=true;
             }

        })

        this.auth.appInfo().subscribe(appInfo => {
            this.modoPrueba = appInfo.mode == "test";
        })

        this.route.queryParams.subscribe(res => {
            if (!AppUtils.isNullOrUndefined(res.url)) {
                this.url = res.url;
            }
        });
    }

    validar(){
        if(this.modoPrueba){
        return !this.form.valid || this.modo;
    }else{
        return !this.form.valid
    }
    }

    login(values) {
        this.auth.login(values.user, values.pass).subscribe(
            res => this.success(res),
            res => this.error()
        );
    }

    error() {
        this.snackBarService.error('Usuario o contraseña incorrecto.')
        this.form.get('pass').setValue(null);
        $('#pass').focus();
    }

    success(data: any) {
        localStorage.accessTken = data.token_type + ' ' + data.access_token;
        this.auth.loadSession().subscribe(user => {
            AppUtils.save('currentUser', user);
            console.log("🚀 ~ file: login.component.ts:82 ~ LoginComponent ~ this.auth.loadSession ~ user:", user)

            this.navigation.activeTab = 0;
            this.navigation.config();
            this.navigation.setMenuItemsFury(this.navigation.modules[0].menu);
            this.router.navigateByUrl(this.navigation.firstOption);
        })
    }
}
