<div class="toolbar" fxLayout="row" fxLayoutAlign="space-between">
  <div fxLayout="row" fxLayoutAlign="start center">
    <fury-toolbar-sidenav-mobile-toggle (openSidenav)="openSidenav.emit()"></fury-toolbar-sidenav-mobile-toggle>

    <div [fxShow.gt-sm]="topNavigation$ | async" [fxShow.xs]="false"  class="toolbar-logo" fxShow>
        <img style="width: 100px;" src="assets/img/logo_black.png">
    </div>

   <!--  <fury-toolbar-search-bar fxFlex="500px" fxHide fxShow.gt-md></fury-toolbar-search-bar> -->

    <div class="modules-nav" fxHide fxShow.gt-md style="width: 716px;">
      <mat-tab-group #tabgroup class="demo-tab-group" (selectedTabChange)="selectModule(true)"
          [(selectedIndex)]="navigation.activeTab" color="accent">
          <div *ngFor="let item of modules;  let i=index">
          <!--   <ng-container *ngIf="i<4"> -->
          <ng-container>
              <mat-tab>
                  <ng-template mat-tab-label>
                      <span style="color: #53ad32">{{item.name}}</span>
                  </ng-template>
              </mat-tab>
          </ng-container>
          </div>

      </mat-tab-group>


  </div>

     <!--  <div class="modules-nav" fxHide fxShow.gt-md>
      <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
          <div *ngFor="let item2 of modules;  let j=index">
          <ng-container *ngIf="j>=4">
          <button mat-menu-item>
              <span style="color: #53ad32" (click)="selectModuleInMenu(true, j)">{{item2.name}}</span>
          </button>

          </ng-container>
          </div>
      </mat-menu>
      </div> -->

  </div>

  <div fxLayout="row">

    <fury-toolbar-modules fxHide.gt-md  class="toolbar-button"></fury-toolbar-modules>

    <fury-toolbar-fullscreen-toggle class="toolbar-button"></fury-toolbar-fullscreen-toggle>

   <!--  <fury-toolbar-search fxHide fxShow.gt-sm fxHide.gt-md class="toolbar-button"></fury-toolbar-search> -->

    <fury-toolbar-notifications class="toolbar-button"></fury-toolbar-notifications>

    <fury-toolbar-user class="toolbar-button"></fury-toolbar-user>

  <!--  <fury-toolbar-quickpanel-toggle (openQuickPanel)="openQuickPanel.emit()"
                                    class="toolbar-button"></fury-toolbar-quickpanel-toggle>  -->
  </div>
</div>

