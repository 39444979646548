import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { CognitoService } from './cognito.service';


@Injectable({
  providedIn: 'root'
})
export class ActivateAuth implements CanActivate {

  constructor(private router: Router, private http: HttpClient, 
    private cognitoService: CognitoService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | import("@angular/router").UrlTree | import("rxjs").Observable<boolean | import("@angular/router").UrlTree> | Promise<boolean | import("@angular/router").UrlTree> {
    return new Promise((resolve, reject) => {    

        this.cognitoService.logear()
            .then(res =>{
                  resolve(true)                
            }).catch(() => resolve(true));            
    
    });
  }  
}
