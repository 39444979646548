import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class ResourceRemoteService {   

    constructor(private httpClient: HttpClient) {
    }

    getUrl2(name: string,nombre: string): Observable<string> {
        return this.httpClient.get('api/resource/'+nombre+ '/nombre/' +name).pipe(map((r: any) => r.data));
    }


    getUrl(name: string): Observable<string> {
        return this.httpClient.get('api/resource/nombre/' + name).pipe(map((r: any) => r.data));
    }

    getBlob(name: string): Observable<any> {
        return this.httpClient.get('api/resource/archive/' + name, {responseType: 'blob'});
    }

    getContent(url: string): Observable<any> {
        return this.httpClient.get(url, {responseType: 'blob'});
    }

}
