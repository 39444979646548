import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthComponent} from './auth.component';
import {LoginComponent} from './login/login.component';
import {routingAuth} from './auth.routing';
import { MaterialModule } from '../../../../@fury/shared/material-components.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        routingAuth
    ],
    declarations: [
        AuthComponent,
        LoginComponent
    ]
})
export class AuthModule {

}
