<div id="table-data2" class="padding-top-20">
    <qx-filter [filters]="filters" [title]="title" (change)="searchByFilters($event)" *ngIf="filters?.length"></qx-filter>
    <div class="table-responsive">
        <table mat-table [dataSource]="list_items" mat-table matSort style="width:100%">
                        
                <ng-container [matColumnDef]="key" *ngFor="let key of keys; let index = index">
                        <th mat-header-cell *matHeaderCellDef>{{ labels[index] }}</th>
                        <td mat-cell *matCellDef="let row" [class]="rowClass(row)">{{ getValue(row, key) }}</td>
                   
                    </ng-container>      

                  <tr *matHeaderRowDef="keys" mat-header-row></tr>
                  <tr (click)="detail(row)"
                      *matRowDef="let row; columns: keys;"
                      class="clickable route-animations-elements"
                      mat-row></tr>
        </table>
        <div style="text-align: center; padding-top: 20px; font-size: 16px;" *ngIf="isEmpty()">No hay resultados</div> 

        <qx-pagination [currentitems]="currentItems"
        [(itemperpage)]="itemperpage"
        [(currentpage)]="currentpage"
        [totalitems]="totalItems"
        [totalpage]="totalPage" (search)="search($event)"></qx-pagination>

      <!--   <mat-paginator class="paginator" [pageSize]="itemperpage"></mat-paginator> -->

    </div>
</div>
