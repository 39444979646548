import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import {
    AuthenticationDetails,
    CognitoUserPool,
    CognitoUser,
    CognitoUserAttribute,
    ICognitoUserPoolData,
    ICognitoUserAttributeData,
    ISignUpResult,
    CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { APP_CONFIG } from './app.config';
import { AppUtils } from './global/helpers/app.utils';
@Injectable({
    providedIn: 'root'
})
export class CognitoService {

    static readonly ACCESS_TOKEN_KEY = 'accesToken';

    oauthAccessToken:any;

    private cognitoUser: CognitoUser;

    constructor(
        private httpClient: HttpClient) {

    }



    public init(username) : Promise<any> {
        return new Promise((resolve, reject) => {

            const config = APP_CONFIG[APP_CONFIG.active];

        var poolData = new CognitoUserPool({
            UserPoolId: config.userPoolId, // Your user pool id here
            ClientId: config.clientId // Your client id here
        });

        this.cognitoUser = new CognitoUser({ Username: username, Pool: poolData });

        resolve(true);
     })
    }

    logear(){
        const config = APP_CONFIG[APP_CONFIG.active];
        var user= config.user;
        var pass= config.pass;

       return this.login(user,pass);
    }

    public login(username, password): Promise<any> {
        return new Promise((resolve, reject) => {
            this.init(username).then(_=>{
                this.getToken(username, password).then(accessToken => {
                    this.saveOauthToken(accessToken).then(_ => {
                       resolve(true)
                    }).catch(e => { reject(false) })
                }).catch(e => { reject(false) })
            })            
        });
    }



    public getToken(username, password): Promise<any> {
        return new Promise((resolve, reject) => {
            var authenticationData = {
                Username: username,
                Password: password,
            };

            this.init(username);

            const authenticationDetails = new AuthenticationDetails(authenticationData);

            this.cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: function (result) {
                    var accessToken = result.getAccessToken().getJwtToken();
                    resolve(accessToken);
                },
                onFailure: function (err) {
                    alert(err.message || JSON.stringify(err));
                    reject(err)
                }
            });

        });

    }

  
    public saveOauthToken(token: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.oauthAccessToken= token;
            AppUtils.save(CognitoService.ACCESS_TOKEN_KEY,token)
            console.log("accessToken" +  this.oauthAccessToken)
            resolve(true)           

        });
    }




   

    public signOut() {
        if (this.cognitoUser != null)
            this.cognitoUser.signOut();
    }



}
