import {Component, EventEmitter, Output, Input} from '@angular/core';

@Component({
    selector: 'button-action',
    templateUrl: './button-action.component.html',
    styleUrls: ['./button-action.component.scss']
})
export class ButtonActionComponent {

    @Output() edit: EventEmitter<any>;
    @Output() delete: EventEmitter<any>;
    @Input() public showEdit:boolean = true;
    @Input() public showDelete:boolean = true;

    constructor() {
        this.edit = new EventEmitter<any>();
        this.delete = new EventEmitter<any>();
    }

}
