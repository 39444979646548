import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SidebarDirective } from '../../@fury/shared/sidebar/sidebar.directive';
import { SidenavService } from './sidenav/sidenav.service';
import { filter, map, startWith } from 'rxjs/operators';
import { ThemeService } from '../../@fury/services/theme.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { checkRouterChildsData } from '../../@fury/utils/check-router-childs-data';
import { AppUtils } from '../global/helpers/app.utils';
import { NavigationService } from '../global/services/navigation.service';
import { SidenavItem } from './sidenav/sidenav-item/sidenav-item.interface';

@Component({
  selector: 'fury-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {

  @ViewChild('configPanel', { static: true }) configPanel: SidebarDirective;
  items: SidenavItem[];
  sidenavOpen$ = this.sidenavService.open$;
  sidenavMode$ = this.sidenavService.mode$;
  sidenavCollapsed$ = this.sidenavService.collapsed$;
  sidenavExpanded$ = this.sidenavService.expanded$;
  quickPanelOpen: boolean;

  sideNavigation$ = this.themeService.config$.pipe(map(config => config.navigation === 'side'));
  topNavigation$ = this.themeService.config$.pipe(map(config => config.navigation === 'top'));
  toolbarVisible$ = this.themeService.config$.pipe(map(config => config.toolbarVisible));
  toolbarPosition$ = this.themeService.config$.pipe(map(config => config.toolbarPosition));
  footerPosition$ = this.themeService.config$.pipe(map(config => config.footerPosition));

  scrollDisabled$ = this.router.events.pipe(
    filter<NavigationEnd>(event => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.scrollDisabled))
  );

  modules = [];

  constructor(private sidenavService: SidenavService,
    private themeService: ThemeService,
    private route: ActivatedRoute,
    private router: Router,
    public navigation: NavigationService) {

  }

  ngOnInit() {
    this.navigation.config();
    this.modules = this.navigation.allModules;
    const module = this.modules[this.navigation.activeTab];
    if (!AppUtils.isNullOrUndefined(module)) {
      this.navigation.cleanMenuItem();
      this.navigation.setMenuItemsFury(module.menu);

      const currentUser = AppUtils.currentUser();
      //console.log("currentUser",currentUser)
      if (!currentUser) {
      console.log("no curren user")
        AppUtils.initHostedUi();
      } else {
        if (this.router.url == 'webapp') {
          const firstMenu = AppUtils.selectFirstMenuShow(module.menu);
          this.router.navigateByUrl(firstMenu.routeOrFunction);
        }
      }

    }
  }


  openQuickPanel() {
    this.quickPanelOpen = true;
  }

  openConfigPanel() {
    this.configPanel.open();
  }

  closeSidenav() {
    this.sidenavService.close();
  }

  openSidenav() {
    this.sidenavService.open();
  }

  ngOnDestroy(): void { }
}

