<mat-form-field floatLabel="never" style="    margin-top: 13px;background-color: #ffff;">
 
  <mat-chip-grid #chipList1 >
      <div *ngIf="title ? title : false" style="padding-right: 19px;
      padding-left: 8px;
      font-size: 19px;
      border-right: 1px solid rgba(0, 0, 0, 0.12);
      margin-bottom: 0px;
      margin-right: 15px;">{{ title }}</div> 
    <mat-chip-row  *ngFor="let chip of chipSelect"
               [removable]="true" [selectable]="true">
      {{chip.value}}      
      <mat-icon matChipRemove (click)="remove(chip)">close</mat-icon>
    </mat-chip-row>
    
    <input id="filtro" matInput [matAutocomplete]="reactiveAuto" placeholder="Buscar..." [formControl]="filterCtrl" [matChipInputFor]="chipList1" (matChipInputTokenEnd)="add($event)">
    
    <mat-autocomplete  id="foco" #reactiveAuto="matAutocomplete" (optionSelected)="selectedOption($event)">
      <mat-option *ngFor="let filter of filters" [value]="filter">
        <span>{{ filter.name }}</span>
        <strong class="demo-secondary-text"> {{filter.text?filter.text:''}} </strong>
      </mat-option>
    </mat-autocomplete>
  </mat-chip-grid>
</mat-form-field>
