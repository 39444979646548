// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
 // googleMapsApiKey: 'AIzaSyD_qW9J2ADDg-6imZc5uUwxznKVfip1M74',
// googleMapsApiKey: 'AIzaSyBTFx7Fg8dYA3nJ12SLzcplR5vqtjvbBLU',
 googleMapsApiKey: 'AIzaSyAT_THkhQHiNNmhLVq7YXTV3jx5tuRx0NY',
 // recapchaV3ApiKey: '6LdC_LIbAAAAABpoDywo_WCGmBzyxipH83O8GSSA',
  recapchaV3ApiKey: '6Lcs3PgaAAAAAKWkvp3AiaG5aXGLOY0ZKNM3eUAT',
  bucket: 'ayto-online-test',
  backend: 'http://localhost:4200', // Put your backend here
  accessKeyId: 'AKIA6E3Z2OQXTT3JSX3N',
  secretAccessKey: 'PfHvVL2emRZyzAobVB5vydDP9kBcDprgV2SJua/+f'
};
