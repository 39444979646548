
import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { AppUtils } from './app.utils';
import * as moment from 'moment';

export class AppValidators {
    static isAnObject(control: AbstractControl): ValidationErrors | null {
        if (!control.value) {
            return null;
        }
        
        if (!AppUtils.isObject(control.value)) {
            return {notOject: true};
        }
        
        return null;
    }

     static isOnlyNumbersAnLeters(control: AbstractControl): ValidationErrors | null {     
        if (!control.value) {
            return null;
        }
        
        if (!AppUtils.isNullOrUndefined(control.value) && control.value!="") {
         
            for (let index = 0; index < (control.value as String).length; index++) {
                let element:String =  (control.value as String)[index];
                if(!element.match(/^[0-9a-zA-Z]/) ){                 
                    return {notOject: true};
                }                
            }
        }
        
        return null;
    }

    static isOnlyNumber(control: AbstractControl): ValidationErrors | null {
        if (!control.value) {
            return null;
        }
        
            if (Number.isFinite(control.value)){ 
                if( Number.parseFloat(control.value)  < 0 ){                
                    return { notNumber: true };
                }          
                
            }else{
                return { notNumber: true };
            }
      
        return null;
    }

    static isInteger(control: AbstractControl): ValidationErrors | null {
        if (!control.value) {
            return null;
        }
            if (!Number.isInteger(control.value)){  
                    return { notInteger: true };                       
            }else{
                if( Number.parseFloat(control.value)  < 0 ){                
                    return { notNumber: true };
                }    
            }
      
        return null;
    }

    static isNumberNegative(control: AbstractControl): ValidationErrors | null {
        if (!control.value) {
            return null;
        }
        
            if (!Number.isFinite(control.value)){                         
                    return { notNumber: true };                     
            }
      
        return null;
    }

    static isCurpValid(control: AbstractControl): ValidationErrors | null {
        if(!control.value){
            return null;
        }

        var re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
        validado = control.value.match(re);

        if(!validado){
            return { notCurp: true };
        }

        return null;

    }

    public static curpValida(curp) {
        var re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
            validado = curp.match(re);

        if (!validado)  //Coincide con el formato general?
            return false;


        /*if (validado[2] != this.digitoVerificador(validado[1]))
          return false;*/

        return true; //Validado
    }

    //Validar que coincida el dígito verificador
    public static digitoVerificador(curp17) {
        //Fuente https://consultas.curp.gob.mx/CurpSP/
        var diccionario = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ",
            lngSuma = 0.0,
            lngDigito = 0.0;
        for (var i = 0; i < 17; i++)
            lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
        lngDigito = 10 - lngSuma % 10;
        if (lngDigito == 10) return 0;
        return lngDigito;
    }

    //Handler para el evento cuando cambia el input
    //Lleva la CURP a mayúsculas para validarlo
    public static validarInput(input: string): boolean {
        if (input == null) {
            return false;
        }
        var curp = input.toUpperCase();
        return this.curpValida(curp)  // ⬅ Acá se comprueba
    }

    public static obtenerFechaNacimientoCurp(miCurp) {
        var currentYearCentury = parseInt(new Date().getFullYear().toString().substr(0, 2) + '00');
        var lastYearCentury = (currentYearCentury - 100);

        var birthdate = null;

        var m = miCurp.match(/^\w{4}(\w{2})(\w{2})(\w{2})/);

        var dia = m[3];
        var mes = m[2];
        var ano = m[1];

        var yearCURP = parseInt(m[1]);
        var monthCURP = parseInt(m[2]) - 1;
        var dayCURP = parseInt(m[3]);

        if (monthCURP >= 0 && monthCURP <= 11 && dayCURP >= 1 && dayCURP <= 31) {
            if ((currentYearCentury + yearCURP) > new Date().getFullYear()) {
                birthdate = new Date((lastYearCentury + yearCURP), monthCURP, dayCURP, 0, 0, 0, 0);
            } else {
                birthdate = new Date((currentYearCentury + yearCURP), monthCURP, dayCURP, 0, 0, 0, 0);
            }
        }

        var month = parseInt(m[2]);
        var day = m[3];
        let year = birthdate.getFullYear();


        var fechaString = day + "/" + month + "/" + year;
        var fecha = moment(fechaString, "DD/MM/YYYY").toDate();
        return fecha;
    }

    public static obtenerSexoCurp(miCurp) {
        var sexo = miCurp.substring(10, 11);

        if (sexo == "H") {
            return "MASCULINO";
        } else {
            return "FEMENINO";
        }

    }

    esVacioNull(data){
        if(data==null){
            return true;
        }
        if(data==""){
            return true;
        }
        return false;
    }

}
