import {
    Component, Input, AfterViewInit, Output, EventEmitter, ViewChild, forwardRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, AbstractControl } from '@angular/forms';
import { AppUtils } from '@quiox/global/helpers/app.utils';
import $ from 'jquery';

@Component({
    selector: 'qx-clean-control',
    templateUrl: './qx-clean-control.component.html',
    styleUrls: ['./qx-clean-control.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => QxCleanControlComponent),
        multi: true
    }
    ]
})
export class QxCleanControlComponent implements ControlValueAccessor {
    _value: string;

    @Input() control: AbstractControl;

    @Input() disabled:boolean = false;

    constructor() {

    }

    ngOnInit() {

    }

    writeValue(value: any): void {

    }


    propagateChange = (_: any) => { };

 

    resetValue(): void {
        $('#foco').focus();
        if (!AppUtils.isNullOrUndefined(this.control) && !AppUtils.isNullOrUndefined(this.control.value) && !this.control.disabled && !this.disabled) {
            this.control.setValue(null);
            this.propagateChange(this.control);
        }
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState?(isDisabled: boolean): void {
        //throw new Error("Method not implemented.");
    }
}
