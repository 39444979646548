import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { QxFilterDialog } from '../qx-filter.base';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-qx-filter-input',
  templateUrl: './qx-filter-input.component.html',
  styleUrls: ['./qx-filter-input.component.scss']
})
export class QxFilterInputComponent extends QxFilterDialog {    
    form: FormGroup;    

    constructor(public dialogRef: MatDialogRef<any>, public fb: FormBuilder) {
      super();
        this.form = this.fb.group({
            value: [null, [Validators.required]],
            exactValue:[false]
        });
    }

    keyPress(event) {
        if (event.key == 'Enter' && this.form.valid) {
            this.acepted();
        }
    }

    acepted() {
        this.acept = true;
        this.dialogRef.close();
    }

  get value(): string {
    return this.form.value.value;
  }

  patchParams(params: any) {
    for (let i in params) {
      params[i] = this.value;
    }
     params['exactValue'] = this.form.value.exactValue;
  }

}
