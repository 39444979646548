
export const APP_CONFIG = {
  active: 'prod' ,   //test, prod,dev
  prod: {
    url: 'https://sgkyodow1b.execute-api.us-east-1.amazonaws.com/prod',
    user: 'predial',
    pass: 'Sgspredial2024*',
    userPoolId:'us-east-1_r7HUqI5F2',
    clientId:'655k81lamn4jnud75f197vl4n6'
  },
  test: {
    url: 'https://abj9nff3hj.execute-api.us-east-1.amazonaws.com/release',
    user: 'predialrelease',
    pass: 'Predia996745**',
    userPoolId:'us-east-1_EuqlEyneU',
    clientId:'7obj7e393adjfm0c6mcduof9ur'
  },
  dev: {
    url: 'http://localhost:8000',
    user: 'predialrelease',
    pass: 'Predia996745**',
    userPoolId:'us-east-1_EuqlEyneU',
    clientId:'7obj7e393adjfm0c6mcduof9ur'
  },
};
