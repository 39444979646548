export class Spin {
  opts = {
    lines: 15 // The number of lines to draw
    , length: 31 // The length of each line
    , width: 3 // The line thickness
    , radius: 42 // The radius of the inner circle
    , scale: 1 // Scales overall size of the spinner
    , corners: 1 // Corner roundness (0..1)
    , color: '#000' // #rgb or #rrggbb or array of colors
    , opacity: 0.25 // Opacity of the lines
    , rotate: 21 // The rotation offset
    , direction: 1 // 1: clockwise, -1: counterclockwise
    , speed: 1.1 // Rounds per second
    , trail: 87 // Afterglow percentage
    , fps: 20 // Frames per second when using setTimeout() as a fallback for CSS
    , zIndex: 2e9 // The z-index (defaults to 2000000000)
    , className: 'spinner' // The CSS class to assign to the spinner
    , top: '45%' // Top position relative to parent
    , left: '50%' // Left position relative to parent
    , shadow: false // Whether to render a shadow
    , hwaccel: false // Whether to use hardware acceleration
    , position: 'absolute' // Element positioning
  };  

  public static loading() {
    new Spin().spinLoading();
  }

  public static stop() {
    new Spin().spinStop();
  }

  constructor() {
  }

 
  spinLoading() {
    if (document.getElementById('loaddiv')) {
      document.getElementById('loaddiv').className = 'load';
    }
    
  }

  spinStop() {
    if (document.getElementById('loaddiv')) {
      document.getElementById('loaddiv').className = '';
    }
    
  }


  /* spinLoading() {
    if (document.getElementById('loading')) {
      document.getElementById('loading').className = 'loader';
    }
    if (document.getElementById('table-data')) {
      document.getElementById('table-data').className = document.getElementById('table-data').className + ' opacity-down';
    }
  }

  spinStop() {
    if (document.getElementById('loading')) {
      document.getElementById('loading').className = '';
    }
    if (document.getElementById('table-data')) {
      document.getElementById('table-data').className = document.getElementById('table-data').className.replace(' opacity-down', '');
    }
  } */

}


