import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Inject, Component } from '@angular/core';

@Component({
    selector:"info-component",
    templateUrl:"info.component.html"
})
export class InfoComponent {
    constructor(public snackBarRef: MatSnackBarRef<InfoComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data:any) {       
    }

    nuevos(){
        this.snackBarRef.onAction
    }

}