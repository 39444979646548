import {AfterViewInit, Component, OnInit} from '@angular/core';
import {NavigationService} from '../../services/navigation.service';
import {AuthenticationService} from '../../services/authentication.service';
import {Router} from '@angular/router';
import {User} from '../../models/user';
import {AppUtils} from '../../helpers/app.utils';
import {configCustom} from '../../../src/config/config';
import { DialogPerfil } from '../../pages/dialog-perfil/dialog-perfil.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, AfterViewInit {
    showMenu: boolean = true;
    modules = [];
    currentUser: User;
    indexTab = 0;
    proyecName= configCustom.projectName;
    dialogPerfil: MatDialogRef<DialogPerfil>;
    appInfo: any = {};

    constructor(public navigation: NavigationService,
                public auth: AuthenticationService,
                public router: Router, public dialog: MatDialog, private httpClient: HttpClient) {
    }

    async ngOnInit() {
        this.currentUser = AppUtils.currentUser();        
        this.modules = this.navigation.allModules;        
        /*if (!isNullOrUndefined(this.currentUser)) {
            await this.modules.map(res => {
                // const permission=res.menu.filter(m=>m.roles.indexOf())
                let permission = true;
                for (let i in this.currentUser.authorities) {
                    const authority = this.currentUser.authorities[i].authority;
                    if (this.findAuthorityMenu(authority, res.menu)) {
                        permission = true;
                        break;
                    }
                }
                res['show'] = permission;
            });
            const moduleActive = this.modules.filter(x => x['show']);
            if (moduleActive.length)
                this.selectModule();
        }*/
        this.httpClient.get('api/pago-predial/app-info').subscribe(res => this.appInfo = res);
    }

    selectModule(external = false) {
        const module = this.modules[this.navigation.activeTab];
        if (!AppUtils.isNullOrUndefined(module)) {
            AppUtils.save('activeTab', this.navigation.activeTab);            
            this.navigation.setMenuItems(module);            

            const firstMenu = this.selectFirstMenuShow(module.menu);            
            if (!AppUtils.isNullOrUndefined(firstMenu)) {
                if (external)
                    this.router.navigateByUrl(firstMenu.url);
                else {
                    let index = -1;
                    for (let i in this.modules) {
                        const urlOk = this.urlOk(this.modules[i].menu);
                        if (urlOk) {
                            index = parseInt(i);
                        }
                    }
                    
                    if (index >= 0)
                        this.navigation.activeTab = index;
                }
            }
        }
    }

    selectFirstMenuShow(menus) {
        const menuShow = menus.filter(m => m['show']);
        let firstMenu = menuShow.length ? menuShow[0] : null;
        if (!AppUtils.isNullOrUndefined(firstMenu) && firstMenu.childrens) {
            firstMenu = this.selectFirstMenuShow(firstMenu.childrens);
        }
        return firstMenu;
    }

    urlOk(menus) {
        let urlOk = menus.filter(m => this.router.url.indexOf(m.url) > -1 && m['show']);
        if (!urlOk.length) {
            menus.forEach(m => {
                if (m.childrens) {
                    urlOk = this.urlOk(m.childrens);
                    if (urlOk) {
                        return urlOk;
                    }
                }
            });
        }
        return urlOk.length;
    }

    findAuthorityMenu(authority, menu) {
        let access = false;
        menu.forEach(item => {
            if (item.roles && item.roles.indexOf(authority) >= 0) {
                access = true;
                item['show'] = true;
            } else {
                item['show'] = false;
            }
            if (item.childrens) {
                access = this.findAuthorityMenu(authority, item.childrens);
                item['show'] = access;
            }
        });
        return access;
    }

    ngAfterViewInit() {

    }

    openSideMenu() {
        this.showMenu = !this.showMenu;
        this.navigation.toggleSidenav(this.showMenu);
    }

    logout() {
        this.auth.logout();
        this.navigation.casAuth();
    }

    miPerfil(){
        this.dialogPerfil = this.dialog.open(DialogPerfil, {
            disableClose:true
        });
    }


}
