import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {RouterModule} from '@angular/router';
import {PerfilService} from './perfil.service';
import {PerfilComponent} from './perfil.component';
import {PerfilRoutingModule} from './perfil.routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MaterialModule } from '../../../../@fury/shared/material-components.module';

@NgModule({
    imports: [
        CommonModule,
        PerfilRoutingModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
    ],
    declarations: [
        PerfilComponent,
    ],
    providers:[
        PerfilService
    ],
    bootstrap:[
        PerfilComponent,
    ]
})
export class PerfilModule {

}
